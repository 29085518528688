import { Tabs } from 'antd';
import PropTypes from 'prop-types';

import { StyledCustomTabs } from './styled/CustomTabs.styled';

const { TabPane } = Tabs;

export const CustomTabs = ({ tabs }) => {
    const items = tabs.map(tab => ({
        key: tab.title,
        label: tab.title,
        children: tab.Component,
        disabled: tab.disabled
    }));

    return (
        <StyledCustomTabs>
            <div className="tabs-container">
                <Tabs
                    tabPosition="top"
                    animated={{ inkBar: false, tabPane: false }}
                    items={items}
                />
            </div>
        </StyledCustomTabs>
    );
};

CustomTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            disabled: PropTypes.bool
        })
    )
};
