import { MailOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { adminActions } from 'redux/slices/admin';
import CloseIcon from 'shared/theme/assets/icons/CloseIcon';
import { onKeyPressed } from 'utils/eventsHelper';

import StyledEmailChip from './styled/EmailChip.style';

const EmailChip = ({ notification, className }) => {
    const dispatch = useDispatch();

    const removeEmail = () => {
        dispatch(adminActions.removeModalEmail(notification.emailAddress));
    };

    return (
        <StyledEmailChip className={`user-chip ${className}`}>
            <div className="chip-icon">
                <MailOutlined className="mail-icon" />
            </div>
            <div className="chip-text">
                <div className="email">{notification.emailAddress}</div>
            </div>
            <div
                className="remove-button"
                onClick={removeEmail}
                onKeyDown={e => onKeyPressed(e, removeEmail)}
            >
                <CloseIcon />
            </div>
        </StyledEmailChip>
    );
};

EmailChip.propTypes = {
    notification: PropTypes.shape({
        emailAddress: PropTypes.string
    }),
    className: PropTypes.string
};

export default EmailChip;
