import React, { useCallback } from 'react';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { CalendarIcon } from '../theme/assets/icons/CalendarIcon';
import { StyledDatePicker } from './styled/DatePicker.styled';

const LOCALE = 'en';
const FORMAT = 'MM/DD/YYYY';

const CustomDateSelector = ({
    onChange,
    value,
    name,
    label = '',
    onBlur = () => {},
    disabledDate,
    disabled = false,
    allowClear = true,
    validateDateInFuture = true,
    className,
    specialHandleChange = () => {},
    ...rest
}) => {
    const { hideSuffix } = rest;

    const suffix = () => {
        if (hideSuffix) {
            return null;
        }

        return <CalendarIcon />;
    };

    const handleChange = useCallback(
        val => {
            if (val) {
                onChange(name, dayjs(val).format(FORMAT));
                onBlur(name, dayjs(val).format(FORMAT));
            } else {
                onChange(name, null);
                specialHandleChange(onChange);
                onBlur(name, null);
            }
        },
        [name, onBlur, onChange, specialHandleChange]
    );

    const handleDisableDate = current => {
        if (validateDateInFuture) {
            return current && current < dayjs(disabledDate).endOf('day');
        }
        return current && current > dayjs(disabledDate).endOf('day');
    };

    return (
        <StyledDatePicker>
            <div className={`custom-datepicker ${className}`}>
                {label && <div className="input-label">{label}</div>}
                <DatePicker
                    data-testid={name}
                    value={value && dayjs(value)}
                    onChange={handleChange}
                    locale={LOCALE}
                    format={FORMAT}
                    placeholder={FORMAT}
                    suffixIcon={suffix()}
                    disabledDate={disabledDate && handleDisableDate}
                    disabled={disabled}
                    allowClear={allowClear}
                />
            </div>
        </StyledDatePicker>
    );
};

CustomDateSelector.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    disabledDate: PropTypes.bool,
    disabled: PropTypes.bool,
    allowClear: PropTypes.bool,
    validateDateInFuture: PropTypes.bool,
    className: PropTypes.string,
    specialHandleChange: PropTypes.func,
    rest: PropTypes.object
};

export { CustomDateSelector };
