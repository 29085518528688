import React, { useState, forwardRef, useCallback } from 'react';

import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { deleteGroup } from 'redux/slices/groups';
import { deleteProfileMember } from 'redux/slices/members';
import { CustomConfirmationModal } from 'shared/components/ConfirmationModal';
import CloseIcon from 'shared/theme/assets/icons/CloseIcon';

import ArrowDropDownIcon from '../../shared/theme/assets/icons/ArrowDropDownIcon';
import MoreIcon from '../../shared/theme/assets/icons/MoreIcon';
import UserGroupModal from '../../wfh/modals/Groups/UserGroupModal';
import StyledUsersList from './styled/UsersList.styled';
import UserWorkWeek from './UserWorkWeek';

const UsersList = forwardRef(
    ({ className = '', group, week, membersRef }, ref) => {
        const dispatch = useDispatch();
        const [groupModalOpen, setGroupModalOpen] = useState(false);
        const [confirmationModalOpen, setConfirmationModalOpen] =
            useState(false);
        const [dropdownOpen, setDropdownOpen] = useState(false);
        const [hidden, setHidden] = useState(true);

        const onClick = useCallback(() => {
            setHidden(state => !state);
        }, []);

        const removeGroup = useCallback(() => {
            if (group.isGroupMember) {
                dispatch(deleteProfileMember(group.profileMemberId));
            } else {
                dispatch(deleteGroup(group.id));
            }
            setConfirmationModalOpen(() => !confirmationModalOpen);
        }, [group, confirmationModalOpen, dispatch]);

        const toggleAddGroup = useCallback(() => {
            setGroupModalOpen(open => !open);
        }, []);

        const toggleConfirmationModal = useCallback(() => {
            setConfirmationModalOpen(open => !open);
        }, []);

        const toggleDropDown = useCallback(() => {
            setDropdownOpen(() => !dropdownOpen);
        }, [dropdownOpen]);

        const items = [
            {
                label: (
                    <div
                        className="option"
                        onClick={e => {
                            toggleAddGroup();
                            e.stopPropagation();
                            setDropdownOpen(() => !dropdownOpen);
                        }}
                        onKeyDown={() => {}}
                    >
                        Edit
                    </div>
                ),
                key: 'edit'
            },
            {
                label: (
                    <div
                        className="option"
                        onClick={e => {
                            e.stopPropagation();
                            setDropdownOpen(() => !dropdownOpen);
                            toggleConfirmationModal();
                        }}
                        onKeyDown={() => {}}
                    >
                        Remove
                    </div>
                ),
                key: 'remove'
            }
        ];

        const groupParent = group.parentId ? (
            <div></div>
        ) : (
            <div
                className="more"
                onClick={e => {
                    e.stopPropagation();
                    toggleConfirmationModal();
                }}
                onKeyDown={() => {}}
                data-testid="remove-button"
            >
                <CloseIcon />
            </div>
        );

        const options = group.isGroupMember ? (
            groupParent
        ) : (
            <Dropdown
                className="more"
                menu={{ items }}
                trigger={['click']}
                placement="topRight"
                open={dropdownOpen}
                onOpenChange={toggleDropDown}
            >
                <div
                    onClick={e => {
                        e.stopPropagation();
                        setDropdownOpen(() => !dropdownOpen);
                    }}
                    onKeyDown={() => {}}
                    className="action-button-container"
                    data-testid="edit-button"
                >
                    <MoreIcon />
                </div>
            </Dropdown>
        );

        return (
            <StyledUsersList
                className={`${className} ${hidden ? '' : 'expanded'}`}
            >
                <div
                    className="list-name"
                    onClick={() => onClick()}
                    onKeyDown={() => {}}
                    ref={ref}
                >
                    <ArrowDropDownIcon
                        className={`dropdown ${hidden ? 'rotate' : ''}`}
                    />
                    <div className="group-name">{group.name}</div>
                    {options}
                </div>
                <span className="list">
                    {group.members?.map(member => (
                        <UserWorkWeek
                            week={week}
                            key={member.id}
                            person={member}
                            ref={el => {
                                if (!membersRef.current[member.id]) {
                                    membersRef.current[member.id] = el;
                                }
                            }}
                            isGroupMember={true}
                            membersRef={membersRef}
                        />
                    ))}
                </span>
                {!group.isGroupMember && groupModalOpen && (
                    <UserGroupModal
                        groupId={group.id}
                        isEdit={true}
                        visible={groupModalOpen}
                        closeModal={toggleAddGroup}
                    />
                )}
                <CustomConfirmationModal
                    visible={confirmationModalOpen}
                    setVisible={toggleConfirmationModal}
                    handleConfirm={removeGroup}
                    title={'Remove Group'}
                />
            </StyledUsersList>
        );
    }
);

UsersList.propTypes = {
    className: PropTypes.string,
    group: PropTypes.shape({
        id: PropTypes.string,
        parentId: PropTypes.string,
        profileMemberId: PropTypes.string,
        name: PropTypes.string,
        isGroupMember: PropTypes.bool,
        members: PropTypes.array
    }),
    week: PropTypes.array,
    membersRef: PropTypes.object
};

export default UsersList;
