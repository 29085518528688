import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import authService from '../../auth/services/authService';
import defaultLogo from '../../shared/theme/assets/images/placeholder-profile.png';
import StyledUserChip from './styled/UserChip.styled';

const UserChip = ({ className = '', person, showEmail = false }) => {
    const [profile, setProfile] = useState({
        ...person
    });

    const profileInfo = useSelector(state => state.profiles.currentProfile);

    useEffect(() => {
        if (!person) {
            const res = authService.getProfile();
            if (res != null) {
                const { name, picture } = res;
                setProfile({
                    name: name,
                    photo: picture,
                    profile: {
                        title: profileInfo.title
                    }
                });
            }
        } else {
            setProfile({ ...person });
        }
    }, [person, profileInfo.title]);

    return (
        <StyledUserChip className={`user-chip ${className}`}>
            <img
                src={profile.photo != null ? profile.photo : defaultLogo}
                alt="User Profile"
                className="chip-image"
                referrerPolicy="no-referrer"
            />
            <div className="chip-text">
                <div className="user-name">{profile.name}</div>
                {showEmail ? (
                    <div className="user-title">{profile.emailAddress}</div>
                ) : (
                    <div className="user-title">{profile.profile?.title}</div>
                )}
            </div>
        </StyledUserChip>
    );
};

UserChip.propTypes = {
    className: PropTypes.string,
    person: PropTypes.shape({
        id: PropTypes.string
    }),
    showEmail: PropTypes.bool
};

export default UserChip;
