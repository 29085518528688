import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

httpService.axios.interceptors.request.use(request => {
    if (getJwt()) {
        request.headers['Authorization'] = `Bearer ${getJwt()}`;
    }
    return request;
});

const googleLogin = async googleToken => {
    const data = await httpService.post(url.GOOGLE_USER_LOGIN(), null, {
        headers: {
            Authorization: `Bearer ${googleToken}`
        }
    });

    return data.data;
};

const microsoftLogin = async microsoftToken => {
    const data = await httpService.post(url.MICROSOFT_USER_LOGIN(), null, {
        headers: {
            Authorization: `Bearer ${microsoftToken}`
        }
    });
    return data.data;
};

const logout = () => {
    localStorage.clear();
    localStorage.setItem('logout', 'logout');
    sessionStorage.clear();
};

const getJwt = () => {
    return localStorage.getItem('token');
};

const getProfile = () => {
    return JSON.parse(localStorage.getItem('profile'));
};

const getUserId = () => {
    return getProfile().userId;
};

const getProfileId = () => {
    return getProfile().profileId;
};

const getProfileDirectoryId = () => {
    return getProfile().directoryId;
};

export default {
    googleLogin,
    microsoftLogin,
    logout,
    getJwt,
    getProfile,
    getUserId,
    getProfileId,
    getProfileDirectoryId
};
