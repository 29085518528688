import React, { useCallback } from 'react';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import StyledCustomMonthYearPicker from './styled/MonthYearPicker.styled';

const FORMAT = 'MM/YYYY';

const CustomMonthPicker = props => {
    const {
        label,
        value,
        className,
        onChange,
        disabled,
        allowClear,
        disabledDate,
        validateDateInFuture = true
    } = props;

    const handleChange = useCallback(
        newValue => {
            onChange(newValue);
        },
        [onChange]
    );

    const handleDisableDate = current => {
        if (validateDateInFuture) {
            return current && current < dayjs(disabledDate).endOf('month');
        }
        return current && current > dayjs(disabledDate).endOf('month');
    };

    return (
        <StyledCustomMonthYearPicker>
            <div className={`custom-month-year-picker ${className}`}>
                {label && <div className="picker-label">{label}</div>}
                <DatePicker
                    picker="month"
                    value={value && dayjs(value)}
                    onChange={handleChange}
                    format={FORMAT}
                    placeholder={FORMAT}
                    disabled={disabled}
                    disabledDate={disabledDate && handleDisableDate}
                    allowClear={allowClear}
                />
            </div>
        </StyledCustomMonthYearPicker>
    );
};

CustomMonthPicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.object,
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    allowClear: PropTypes.bool,
    disabledDate: PropTypes.instanceOf(Date),
    validateDateInFuture: PropTypes.bool
};

export default CustomMonthPicker;
