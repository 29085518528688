import React from 'react';

import PropTypes from 'prop-types';
import { onKeyPressed } from 'utils/eventsHelper';

const TabItem = ({ label, onClickHandler, activeTab, disabled }) => {
    const onClick = () => {
        onClickHandler(label);
    };

    let className = 'tab-list-item';

    if (activeTab === label) {
        className += ' tab-list-active';
    }

    if (disabled) {
        className += ' disabled';
    }

    return (
        <li
            className={className}
            onClick={onClick}
            onKeyDown={e => onKeyPressed(e, onClick)}
        >
            {label}
        </li>
    );
};

TabItem.propTypes = {
    label: PropTypes.string,
    onClickHandler: PropTypes.func,
    activeTab: PropTypes.string,
    disabled: PropTypes.bool
};

export default TabItem;
