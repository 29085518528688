import React, { useCallback } from 'react';

import { Input } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { lettersOnlyRegex } from '../constants/regexConstants';
import StyledCustomInput from './styled/Input.styled';

const CustomInput = ({
    className,
    value,
    onChange,
    label,
    placeholder,
    iconComponent,
    disabled = false,
    type = 'text',
    hasMargins = false,
    allowClear = false,
    onBlur = () => {},
    onKeyPress = () => {},
    onPaste = () => {},
    onFocus = () => {},
    allowLettersOnly = false,
    maxLettersLength,
    ...rest
}) => {
    const { prefix, name, isEditMode, noLabelStyle, specialEnter } = rest;
    const handleBlur = useCallback(() => onBlur(name), [name, onBlur]);
    const handlePressEnter = () => {
        onBlur(name);
    };
    const handleClick = useCallback(
        target => {
            if (value && isEditMode) {
                target.target.setSelectionRange(0, target.target.value.length);
            }
        },
        [value, isEditMode]
    );

    const handleKeyDown = useCallback(
        e => {
            if (allowLettersOnly) {
                const pressedKey = String.fromCharCode(
                    !e.charCode ? e.which : e.charCode
                );

                const matchesRegex = new RegExp(lettersOnlyRegex).test(
                    pressedKey
                );

                if (maxLettersLength) {
                    const isAboveLimit =
                        e.target.value.length > maxLettersLength - 1;

                    const shouldProhibitTyping = matchesRegex && isAboveLimit;
                    if (shouldProhibitTyping) {
                        e.preventDefault();
                    }
                }

                if (!matchesRegex) {
                    e.preventDefault();
                }
            }
        },
        [allowLettersOnly, maxLettersLength]
    );

    const handlePaste = useCallback(
        e => {
            const clipboardString = e.clipboardData.getData('Text').toString();
            const matchesRegex = new RegExp(lettersOnlyRegex).test(
                clipboardString
            );
            let shouldProhibitPaste;
            //if current value exists compare the clipboard data against it
            if (!isEmpty(value)) {
                const totalLength = value.length + clipboardString.length;
                shouldProhibitPaste =
                    (allowLettersOnly && !matchesRegex) ||
                    totalLength > maxLettersLength;
            } else {
                shouldProhibitPaste =
                    (allowLettersOnly && !matchesRegex) ||
                    clipboardString.length > maxLettersLength;
            }

            if (shouldProhibitPaste) {
                e.preventDefault();
            }
        },
        [value, allowLettersOnly, maxLettersLength]
    );

    return (
        <StyledCustomInput>
            <div
                className={`customInput 
                ${className ?? ''}
                ${hasMargins ? ' inputMargin' : ''} 
                ${prefix ? ' hasPrefix' : ''}
                ${noLabelStyle ? ' no-label' : ''}`}
            >
                {label && (
                    <div
                        className={disabled ? 'label-disabled' : 'input-label'}
                    >
                        {label}
                    </div>
                )}
                <div className={`search  ${!iconComponent && 'no-icon'}`}>
                    {iconComponent}
                    {allowLettersOnly ? (
                        <Input
                            prefix={prefix}
                            allowClear={allowClear}
                            name={name}
                            aria-label={name}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            type={type}
                            disabled={disabled}
                            onBlur={handleBlur}
                            onFocus={onFocus}
                            onKeyDown={handleKeyDown}
                            onPaste={handlePaste}
                        />
                    ) : (
                        <Input
                            prefix={prefix}
                            allowClear={allowClear}
                            name={name}
                            aria-label={name}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            type={type}
                            disabled={disabled}
                            onBlur={handleBlur}
                            onFocus={onFocus}
                            onClick={handleClick}
                            onPressEnter={specialEnter && handlePressEnter}
                            maxLength={maxLettersLength}
                        />
                    )}
                </div>
            </div>
        </StyledCustomInput>
    );
};

CustomInput.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    iconComponent: PropTypes.element,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    hasMargins: PropTypes.bool,
    allowClear: PropTypes.bool,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,
    onPaste: PropTypes.func,
    onFocus: PropTypes.func,
    allowLettersOnly: PropTypes.bool,
    maxLettersLength: PropTypes.string,
    rest: PropTypes.object
};

export default CustomInput;
