import { useCallback } from 'react';

import { Badge, Button } from 'antd';
import PropTypes from 'prop-types';
import CustomButton from 'shared/components/Button';
import { flexDeskTypeId } from 'shared/constants/generalConstants';
import { STATIC_ROUTES_PATHS } from 'shared/constants/staticRoutesPaths';
import { onKeyPressed } from 'utils/eventsHelper';

import StyledDeskChip from './styled/StyledDeskChip.styled';

const DeskChip = ({ className = '', desk, getDesk, label }) => {
    const openLayout = useCallback(() => {
        let url = STATIC_ROUTES_PATHS.layouts.replace(':deskId', desk.id);
        window.open(url, '_blank');
    }, [desk.id]);

    const openLayoutKeyDown = useCallback(
        e => {
            onKeyPressed(e, () => {
                let url = STATIC_ROUTES_PATHS.layouts.replace(
                    ':deskId',
                    desk.id
                );
                window.open(url, '_blank');
            });
        },
        [desk.id]
    );

    return (
        <StyledDeskChip className={`desk-chip ${className}`}>
            <div
                className="circle"
                onClick={openLayout}
                onKeyDown={openLayoutKeyDown}
                data-testid={`desk-name-${desk.id}`}
            >
                {desk.name}
            </div>
            <div className="desk-info">
                <Button
                    onClick={openLayout}
                    type="link"
                    className="desk-name-label"
                    size="small"
                >
                    Desk {desk.name}
                </Button>
                <div>{label}</div>
            </div>
            {desk.deskTypeId == flexDeskTypeId && <Badge count="Equipped" />}
            <div className="select-button">
                <CustomButton
                    type="select"
                    size="small"
                    onClick={() => getDesk(desk)} // eslint-disable-line react/jsx-no-bind
                >
                    Select
                </CustomButton>
            </div>
        </StyledDeskChip>
    );
};

DeskChip.propTypes = {
    className: PropTypes.string,
    desk: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        deskTypeId: PropTypes.number
    }),
    getDesk: PropTypes.func,
    label: PropTypes.string
};

export default DeskChip;
