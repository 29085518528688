import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getMyProfile } from 'redux/slices/profiles';

import authService from '../../../auth/services/authService';
import { red } from '../../constants/generalConstants';
import ArrowDropDownIcon from '../../theme/assets/icons/ArrowDropDownIcon';
import LogoHorizontalFullColor from '../../theme/assets/icons/LogoHorizontalFullColor';
import ColourTag from '../ColourTag';
import Media from '../Media';
import ProfileSubmenu from '../ProfileSubmenu';
import Navigation from './Navigation';
import StyledHeader from './styled/Header.styled';

const Header = props => {
    const { hasNavigation } = props;
    const ref = useRef();
    const [profileSubMenu, setProfileSubMenu] = useState(false);
    const [profile, setProfile] = useState({
        email: '',
        picture: ''
    });
    const currentProfile = useSelector(state => state.profiles.currentProfile);
    const dispatch = useDispatch();

    const { shouldRefetchProfile } = useSelector(state => state.profiles);

    useEffect(() => {
        if (shouldRefetchProfile) {
            dispatch(getMyProfile());
        }
    }, [shouldRefetchProfile]);

    //fetch user data
    useEffect(() => {
        const res = authService.getProfile();
        if (res != null) {
            const { email, picture } = res;
            setProfile({ email: email, picture: picture });
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [profileSubMenu]);

    const checkIfClickedOutside = e => {
        if (profileSubMenu && ref.current && !ref.current.contains(e.target)) {
            setProfileSubMenu(false);
        }
    };

    const onClickProfileSubMenu = useCallback(() => {
        setProfileSubMenu(!profileSubMenu);
    }, [profileSubMenu]);

    return (
        <StyledHeader className="header">
            <Link className="logo" to="/main">
                <LogoHorizontalFullColor />
            </Link>
            <div className="header-navigation">
                {hasNavigation && <Navigation />}
            </div>
            <div className="flex-grow" />
            {currentProfile.workFromHomeDays && (
                <div className="header-wfh">
                    <p>WFH this month</p>
                    <ColourTag
                        color={red}
                        text={`${currentProfile.workFromHomeDays.used}/${currentProfile.workFromHomeDays.total}`}
                    />
                </div>
            )}
            <div className="header-profile" ref={ref}>
                <Media
                    className={'header-profile-image'}
                    photoUrl={profile.picture}
                    onClick={onClickProfileSubMenu}
                />
                <button
                    className="header-dropdown"
                    onClick={onClickProfileSubMenu}
                >
                    <ArrowDropDownIcon />
                </button>
                <div className="header-profile-submenu">
                    {profileSubMenu && (
                        <ProfileSubmenu profile={currentProfile} />
                    )}
                </div>
            </div>
        </StyledHeader>
    );
};

Header.propTypes = {
    hasNavigation: PropTypes.bool
};

export default Header;
