import React, { useCallback, useEffect, useState } from 'react';

import Modal from 'antd/lib/modal/Modal';
import authService from 'auth/services/authService';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    groupsActions,
    getGroupById,
    createGroup,
    updateGroup
} from 'redux/slices/groups';
import { membersActions } from 'redux/slices/members';
import UserSearch from 'wfh/components/UserSearch';

import CustomButton from '../../../shared/components/Button';
import CustomInput from '../../../shared/components/Input';
import CloseIcon from '../../../shared/theme/assets/icons/CloseIcon';
import GroupMembers from './GroupMembers';
import StyledUserGroupModal, {
    StyledUserGroupModalFooter
} from './styled/UserGroupModal.styled';

const UserGroupModal = ({
    className = '',
    closeModal,
    isEdit = false,
    visible,
    groupId,
    ...rest
}) => {
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(true);
    const [isCurrentMember, setIsCurrentMember] = useState(false);
    const groupData = useSelector(state => state.groups.modalGroup);
    const currentProfileDirectoryId = authService.getProfileDirectoryId();

    useEffect(() => {
        if (isEdit) {
            dispatch(getGroupById(groupId));
        } else {
            dispatch(groupsActions.clearModalGroup());
        }
        dispatch(membersActions.clearSearchResults());
    }, [visible]);

    useEffect(() => {
        setIsValid(groupData.name?.trim() && groupData.members?.length > 0);
    }, [groupData]);

    const onNameChange = useCallback(
        event => {
            dispatch(groupsActions.changeGroupName(event.target.value));
        },
        [dispatch]
    );

    const save = useCallback(() => {
        if (isEdit) {
            dispatch(
                updateGroup({
                    id: groupId,
                    name: groupData.name,
                    members: groupData.members
                })
            );
        } else {
            dispatch(createGroup(groupData));
        }
        closeModal();
    }, [groupData, isEdit, groupId, closeModal, dispatch]);

    const addMember = useCallback(
        member => {
            setIsCurrentMember(member.id === currentProfileDirectoryId);
            if (member.id !== currentProfileDirectoryId) {
                dispatch(groupsActions.addModalMember(member));
            }
        },
        [currentProfileDirectoryId, dispatch]
    );

    const footer = (
        <StyledUserGroupModalFooter>
            <CustomButton
                type="primary"
                className="save-button"
                disabled={!isValid}
                onClick={save}
            >
                Save Group
            </CustomButton>
        </StyledUserGroupModalFooter>
    );

    return (
        <Modal
            open={visible}
            onCancel={closeModal}
            footer={footer}
            title={isEdit ? 'Edit Group' : 'Add Group'}
            centered
            closeIcon={<CloseIcon />}
            {...rest}
        >
            <StyledUserGroupModal
                className={`groups-modal ${className}`}
                data-testid="UserGroupModal"
            >
                <div className="group-name">
                    <CustomInput
                        maxLettersLength="100"
                        placeholder="Group Name"
                        onChange={onNameChange}
                        value={groupData.name}
                    />
                </div>
                <UserSearch
                    addMember={addMember}
                    placeholder="Name or email address"
                />
                {isCurrentMember && (
                    <div className="error-text">
                        Cannot add yourself as a Group Member.
                    </div>
                )}
                <div className="members">Members</div>
                <GroupMembers />
            </StyledUserGroupModal>
        </Modal>
    );
};

UserGroupModal.propTypes = {
    className: PropTypes.string,
    closeModal: PropTypes.func,
    isEdit: PropTypes.bool,
    visible: PropTypes.bool,
    groupId: PropTypes.string
};

export default UserGroupModal;
