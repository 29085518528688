import { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
    flexibleRemoteMentorMaterId,
    homeMentorMaterId,
    officeHomeMentorMaterId
} from 'shared/constants/generalConstants';
import { STATIC_ROUTES_PATHS } from 'shared/constants/staticRoutesPaths';
import { onKeyPressed } from 'utils/eventsHelper';
import profilesService from 'wfh/services/profilesService';

import authService from '../../auth/services/authService';
import StyledProfileSubmenu from './styled/ProfileSubmenu.styled';

const ProfileSubmenu = ({ profile }) => {
    const navigate = useNavigate();
    const handleClickLogout = useCallback(() => {
        authService.logout();
        navigate(STATIC_ROUTES_PATHS.login);
    }, [navigate]);

    const handleOnKeyDown = useCallback(
        e => {
            onKeyPressed(e, () => {
                authService.logout();
                navigate(STATIC_ROUTES_PATHS.login);
            });
        },
        [navigate]
    );

    const mentormaterToString = () => {
        const mentormaterId = profilesService.getMentomaterId(
            profile,
            new Date()
        );
        switch (mentormaterId) {
            case officeHomeMentorMaterId:
                return 'Office + Home';
            case homeMentorMaterId:
                return 'Home';
            case flexibleRemoteMentorMaterId:
                return 'Remote';
            default:
                return 'Undefined';
        }
    };
    return (
        <StyledProfileSubmenu>
            <div className="profile-sub-menu">
                <div className="profile-content" data-testid="profile-sub-menu">
                    <div className="mail">{profile.emailAddress}</div>
                    {process.env.REACT_APP_ENV_NAME !== 'prod' && (
                        <div className="mail">{mentormaterToString()}</div>
                    )}
                    <div className="links">
                        {/* NOSONAR */}
                        <a
                            onClick={handleClickLogout}
                            onKeyDown={handleOnKeyDown}
                        >
                            Log Out
                        </a>
                    </div>
                </div>
            </div>
        </StyledProfileSubmenu>
    );
};

ProfileSubmenu.propTypes = {
    profile: PropTypes.shape({
        emailAddress: PropTypes.string
    })
};

export default ProfileSubmenu;
