import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { datePickerActions } from 'redux/slices/datePicker';
import { onKeyPressed } from 'utils/eventsHelper';

import KeyboardArrowLeft from '../../shared/theme/assets/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '../../shared/theme/assets/icons/KeyboardArrowRight';
import StyledWeekPicker from './styled/WeekPicker.styled';

const WeekPicker = ({ className = '' }) => {
    const dispatch = useDispatch();
    const displayWeekDatesDurationAsString = useSelector(
        state => state.datePicker.weekSpan
    );

    const showNextWeek = () => {
        dispatch(datePickerActions.nextWeek());
    };

    const showPrevWeek = () => {
        dispatch(datePickerActions.prevWeek());
    };

    return (
        <StyledWeekPicker className={`week-picker ${className}`}>
            <div
                onClick={showPrevWeek}
                onKeyDown={e => onKeyPressed(e, showPrevWeek)}
            >
                <KeyboardArrowLeft className="picker-arrow" />
            </div>
            <p>{displayWeekDatesDurationAsString}</p>
            <div
                onClick={showNextWeek}
                onKeyDown={e => onKeyPressed(e, showNextWeek)}
            >
                <KeyboardArrowRight className="picker-arrow" />
            </div>
        </StyledWeekPicker>
    );
};

WeekPicker.propTypes = {
    className: PropTypes.string
};

export default WeekPicker;
