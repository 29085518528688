import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { onKeyPressed } from 'utils/eventsHelper';

import placeholder from '../theme/assets/images/placeholder-profile.png';

const Media = ({ className, photoUrl, onClick = () => {}, dataTestId }) => {
    const [avatarUrl, setAvatarUrl] = useState(photoUrl);

    useEffect(() => {
        if (photoUrl !== avatarUrl) {
            setAvatarUrl(photoUrl);
        }
    }, [photoUrl, setAvatarUrl]);

    const handleError = () => {
        setAvatarUrl(placeholder);
    };

    return (
        <img
            data-testid={dataTestId}
            className={className}
            src={avatarUrl ?? placeholder}
            onClick={onClick}
            onKeyDown={e => onKeyPressed(e, onClick)}
            alt="Profile Picture"
            onError={handleError}
        />
    );
};

Media.propTypes = {
    className: PropTypes.string,
    photoUrl: PropTypes.string,
    onClick: PropTypes.func,
    dataTestId: PropTypes.string
};

export default Media;
