import { useCallback, useState } from 'react';

import Icon from '@ant-design/icons';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import SelectIcon from '../theme/assets/icons/SelectIcon';
import SelectIconUp from '../theme/assets/icons/SelectIconUp';
import { StyledCustomSelect } from './styled/CustomSelect.styled';

const { Option } = Select;

const DEFAULT_FILTER_OPTIONS = (input, option) => {
    return option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const CustomSelect = ({
    label = '',
    value,
    onChange = () => {}, // setFieldValue needed when used in formik
    disabled = false,
    name,
    placeholder = '',
    options = [],
    allowClear = false,
    required = false,
    defaultValue,
    mode,
    onBlur = () => {},
    filterOption = DEFAULT_FILTER_OPTIONS,
    ...rest
}) => {
    const { hasInput, onSearch = () => {}, hideSuffix } = rest;
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = useCallback(
        val => {
            onChange(name, val);
            onBlur(name, val);
        },
        [name, onChange, onBlur]
    );

    const suffix = () => {
        if (hideSuffix) {
            return null;
        }

        return suffixIcon;
    };

    const suffixIcon = isOpen ? (
        <Icon component={SelectIconUp} />
    ) : (
        <Icon component={SelectIcon} />
    );

    const requiredStar = required ? <div className="required">*</div> : <></>;

    return (
        <StyledCustomSelect>
            <div className="custom-select-div">
                {label && (
                    <div className="custom-select-label">
                        {requiredStar}
                        {label}
                    </div>
                )}
                {hasInput ? (
                    <Select
                        data-testid={name}
                        showSearch={true}
                        optionFilterProp="label"
                        filterOption={filterOption}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        value={
                            value === '' || value === null ? undefined : value
                        }
                        onChange={handleChange}
                        onSearch={onSearch}
                        suffixIcon={suffix()}
                        options={options}
                        onDropdownVisibleChange={setIsOpen}
                        virtual={false}
                        allowClear={allowClear}
                    ></Select>
                ) : (
                    <Select
                        data-testid={name}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        value={value === null ? undefined : value}
                        onChange={handleChange}
                        suffixIcon={suffix()}
                        onDropdownVisibleChange={setIsOpen}
                        virtual={false}
                        allowClear={allowClear}
                        defaultValue={defaultValue}
                        mode={mode}
                        showSearch={false}
                    >
                        {options}
                    </Select>
                )}
            </div>
        </StyledCustomSelect>
    );
};

CustomSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    size: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    allowClear: PropTypes.bool,
    required: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mode: PropTypes.string,
    onBlur: PropTypes.func,
    filterOption: PropTypes.func,
    rest: PropTypes.object
};

export default CustomSelect;
