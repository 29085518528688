import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import adminService from 'admin/services/adminService';

const initialState = {
    notifications: null,
    modalNotification: {
        id: null,
        name: '',
        region: '',
        locationNotifications: []
    },
    isLoading: false
};

/* istanbul ignore next */
export const getLocationsWithNotifications = createAsyncThunk(
    'admin/getLocationsWithNotifications',
    async () => {
        const response = await adminService.getLocationsWithNotifications();
        return response;
    }
);

/* istanbul ignore next */
export const getLocationWithNotifications = createAsyncThunk(
    'admin/getLocationWithNotifications',
    async locationId => {
        const response = await adminService.getLocationWithNotifications(
            locationId
        );
        return response;
    }
);

/* istanbul ignore next */
export const updateLocationNotifications = createAsyncThunk(
    'admin/updateLocationNotifications',
    async location => {
        const response = await adminService.updateLocationNotifications(
            location.id,
            location.locationNotifications
        );
        return response;
    }
);

const { reducer: adminReducer, actions } = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        clearNotifications(state) {
            state.notifications = initialState.notifications;
        },
        addModalEmail(state, action) {
            const item = action.payload;
            if (
                !state.modalNotification.locationNotifications?.find(
                    x => x.emailAddress === item.emailAddress
                )
            ) {
                state.modalNotification.locationNotifications.push(item);
            }
        },
        removeModalEmail(state, action) {
            state.modalNotification.locationNotifications =
                state.modalNotification.locationNotifications.filter(
                    x => x.emailAddress != action.payload
                );
        }
    },
    extraReducers: builder => {
        builder.addCase(
            getLocationsWithNotifications.pending,
            (state, action) => {
                state.isLoading = true;
            }
        );
        builder.addCase(
            getLocationsWithNotifications.fulfilled,
            (state, action) => {
                state.notifications = action.payload.data;
                state.isLoading = false;
            }
        );
        builder.addCase(
            getLocationWithNotifications.fulfilled,
            (state, action) => {
                state.modalNotification = action.payload.data;
            }
        );
        builder.addCase(
            updateLocationNotifications.fulfilled,
            (state, action) => {
                let toUpdate = state.notifications.find(
                    x => x.id == state.modalNotification.id
                );
                toUpdate.locationNotifications = action.payload.data;
            }
        );
    }
});

export { adminReducer, actions as adminActions };
